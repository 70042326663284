html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
}

* {
  box-sizing: border-box;
}

#gametype__trigger path {
	stroke: white;
	stroke-width: 1px;
	fill:  transparent;
	stroke-dasharray: 400;
	stroke-dashoffset: 400;
	transition: fill 0.45s ease-in-out, stroke-dashoffset 0.45s 0.45s ease-in-out;
}

#gametype__trigger.on--visible path{
  stroke-dashoffset: 0;
  fill: white;
  transition: fill 0.45s 1.35s ease-in-out, stroke-dashoffset 1.8s 0.45s ease-in-out
}

.footer {
  .swiper-button-prev,
  .swiper-button-next {
    color: #5CA3ED;
    transform: scale(0.7);
  }
  .swiper-button-next {
    right: 20px;
  }
}