.container {
  opacity: 1;
}

.list {
  transition: left .7s ease-in-out;
  opacity: 0;
  position: absolute;
  left: -5rem;
}

.cs:hover .container .list{
  opacity: 1;
  left: -6rem;
}
