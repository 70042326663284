.sub-menu, .sub-menu-none {
  opacity: 0;
  transition: all .6s ease-in-out;
  height: 0;
  top: 96px;
}

.sub-menu-item {
  opacity: 0;
  transition: all 2s ease-in-out 4s;
  position: relative;
}

.menu:hover .sub-menu{
  opacity: 1;
  height: 300px;
}

.menu:hover .sub-menu-item {
  opacity: 1;
} 
