@keyframes Spin {
  from { 
    color: transparent;
    opacity: 0.3;
    -webkit-text-stroke: 2px #fff;
  }
  to { 
    color: #fff; 
    opacity: 1;
    -webkit-text-stroke: 2px #fff;
  }
}

.fill-text[animate="0"] {
  animation: none;
}

.fill-text[animate="1"] {
  animation: Spin 2s ease-in-out alternate;
  animation-fill-mode: forwards;
}

.box > span {
  width: 100% !important;
  height: 100% !important;
}

.image {
  width: 100%;
  height: 100%;
}